import React from 'react';
import Marquee from "react-fast-marquee";
import sliderIcon from '../../../src/images/slider-right.png';


const Slider = () => {
  return (
    <Marquee speed={100} delay={0} direction="right">
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div>
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div>
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div>
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div>
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
          <div className="image_wrapper">
            <img src={sliderIcon} alt="Slider-Icon" style={{height:50, width:90,padding: '5px'}} />
          </div>
        </Marquee>
  );
};

export default Slider;
