import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [show, setShow] = useState(false);

  const showAndHideHandler = () => {
    setShow(!show);
  };

  return (
    <header>
      <a href="/" className="logo">
        MIGHTY MOUSE
      </a>
      
      <div
        id="menu-bar"
        className="fas fa-bars"
        onClick={showAndHideHandler}
      ></div>

      <nav className={show ? 'navbar active' : 'navbar'} id="nav">
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#roadmap">Roadmap</a>
        <a href="#tokenomics">Tokenomics</a>
        <a href="#airdrop">Airdrop</a>
      </nav>
    </header>
  );
};

export default Header;
