import React from 'react';

import homeImage from '../../../src/images/playtoearn.png';
import './About.css';

const About = () => {
  return (
    <section className="about" id="about">
      <div className="image">
        <img src={homeImage} alt="home page" />
      </div>
      <div className="content">
      <h3>Mighty Mouse on Solana Blockchain</h3>
        <p>
        Get ready to unleash the power of the internet with Mighty Mouse, the ultimate meme token! This tongue-in-cheek token is designed to bring a little bit of whimsy and humor to the world of cryptocurrency. With no practical usage or utility, Mighty Mouse is purely a symbol of internet culture and a celebration of our shared love of memes. Whether you're a seasoned meme enthusiast or just looking to add some fun to your digital wallet, Mighty Mouse is the perfect token for you!
        </p>
        <a href="https://t.me/mightymouse_sol" target="_blank" className="btn">
          JOIN OUR TELEGRAM
        </a>
        <a href="https://x.com/mightymouse_sol" target="_blank" className="btn">
          FOLLOW ON TWITTER
        </a>
      </div>
    </section>
  );
};

export default About;
