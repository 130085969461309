import React from 'react';

import './Footer.css';

const Footer = () => {
  return (
    <section className="footer">
      <h1 className="credit">
      Contact us: mimoonsol@gmail.com
      </h1>
      <h1 className="credit">
      © 2024 Mighty Mouse. All rights reserved.
      </h1>
    </section>
  );
};

export default Footer;
