import React from 'react';
import homeImage from '../../../src/images/tokenomics.png';
import './Tokenomics.css';

const Roadmap = () => {
  return (
    <section className="tokenomics" id="home">
      <div className="content">
        <h3>DISTRIBUTION:</h3>
        <p className='subt'>
          Token Address: GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi
        </p>
        <p className='white'>⚫ TOTAL SUPPLY: 100,000,000 MIMO</p>
        <p className='white'>🔴 PRESALE - 50,000,000 MIMO</p>
        <p className='white'>🟡 LIQUIDITY - 45,000,000 MIMO</p>
        <p className='white'>🟢 AIRDROP - 3,000,000 MIMO</p>
        <p className='white'>🔵 OWNER - 2,000,000 MIMO</p>
        <a href="https://solscan.io/token/GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi" className="btn">
          SOLSCAN
        </a>
        <a href="https://rugcheck.xyz/tokens/GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi" className="btn">
          RUGCHECK
        </a>
      </div>
      <div className="image">
        <img src={homeImage} alt="Roadmap-Banner" />
      </div>
    </section>
  );
};

export default Roadmap;
