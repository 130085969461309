import React from 'react';

import homeImage from '../../../src/images/gift.png';
import './airdrop.css';

const Airdrop = () => {
  return (
    <section className="airdrop" id="airdrop">
      <div className="image">
        <img src={homeImage} alt="Airdrop Banner" />
      </div>
      <div className="content">
      <h3>How to join MIMO airdop?</h3>
        <p className='white'>➤ Follow Mighty Mouse on X.</p>
        <p className='white'>➤ Join our Telegram</p>
        <p className='white'>➤ Like & Retweet this post ➤ <a href="https://x.com/mightymouse_sol/status/1833294319362437550">
         LINK HERE
        </a></p>
        <p className='white'>➤ Comment your SOL wallet address on this post ➤ <a href="https://x.com/mightymouse_sol/status/1833294319362437550">
         LINK HERE
        </a></p>
        <p>Airdrop Distribution is after the listing on Raydium.</p>
      </div>
    </section>
  );
};

export default Airdrop;
