import React from 'react';
import './App.css';

import Header from './components/header';
import Home from './components/home';
import About from './components/about';
import Roadmap from './components/roadmap';
import Tokenomics from './components/tokenomics';
import Airdrop from './components/airdrop';
import Footer from './components/footer';
import SliderR from './components/slider-right';
import SliderL from './components/slider-left';

const App = () => {
  return (
    <div className="main-container">
      <Header />
      <Home />
      <SliderR />
      <About />
      <SliderL />
      <Roadmap />
      <SliderR />
      <Tokenomics />
      <SliderL />
      <Airdrop />
      <Footer />
    </div>
  );
};

export default App;
