import React from 'react';
import homeImage from '../../../src/images/home-banner.png';
import './Home.css';

const Home = () => {
  return (
    <section className="home" id="home">
      <div className="content">
        <h3>Mighty Mouse (MIMO)</h3>
        <p className='subt'>
          Token Address: GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi
        </p>
        <p className='note'>IMPORTANT: DO NOT SEND SOL TO TOKEN ADDRESS !!</p>
        <p className='white'>
          EXCHANGES:
        </p>
        <a href="https://raydium.io/swap/?inputMint=GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi&outputMint=sol" target="_blank" className="btn">
          RAYDIUM
        </a>
        <a href="https://www.orca.so/?tokenIn=GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi&tokenOut=So11111111111111111111111111111111111111112" target="_blank" className="btn">
          ORCA
        </a>
        <a href="https://jup.ag/swap/GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi-SOL" target="_blank" className="btn">
          JUPITER
        </a>
        <p className='white'>
          DEX CHART:
        </p>
        <a href="https://dexscreener.com/solana/GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi" target="_blank" className="btn">
          DEXSCRENNER
        </a>
        <a href="https://www.geckoterminal.com/solana/pools/6iMskWx7Vc13j6yoobndwdJpJW5EAjZhDvvx8s1HYMS3" target="_blank" className="btn">
          GECKOTERMINAL
        </a>
        <a href="https://www.dextools.io/app/en/solana/pair-explorer/GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi" target="_blank" className="btn">
          DEXTOOLS
        </a>
        <p className='white'>
          SECURITY:
        </p>
        <a href="https://rugcheck.xyz/tokens/GE4QG36zm9UvJ3ZPMCpeifcEotrjBAFkMW5VvBZmmFPi" target="_blank" className="btn">
          RUGCHECK
        </a>
      </div>

      <div className="image">
        <img src={homeImage} alt="home page" />
      </div>
    </section>
  );
};

export default Home;
