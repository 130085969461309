import React from 'react';
import homeImage from '../../../src/images/Roadmap.png';
import './styles.css';

const Roadmap = () => {
  return (
    <section className="roadmap" id="home">
      <div className="image">
        <img src={homeImage} alt="Roadmap-Banner" />
      </div>
      <div className="content">
        <h3>PROGRESS:</h3>
        <p className='white'>🟢 FINISHED - Launched Official Twitter & Telegram</p>
        <p className='white'>🟢 FINISHED - Launched Official Website</p>
        <p className='white'>🟢 FINISHED - Launched MIMO Token Address</p>
        <p className='white'>🟢 FINISHED - Listed on Promotion and Voting Website</p>
        <p className='white'>🟢 FINISHED - MIMO Token Presale</p>
        <p className='white'>🟢 FINISHED - Add 100% of Liquidity on Raydium (MIMO/SOL)</p>
        <p className='white'>🟢 FINISHED - Locked 100% of Liquidity</p>
        <p className='white'>🟡 ONGOING - Airdrop Distribution</p>
        <p className='white'>🔴 COMING SOON - Coingecko & CMC Listing</p>
        <p className='white'>🔴 COMING SOON - CEX Listing</p>
        <p className='red'>After completing this progress, we will announce the continuation of the roadmap.</p>
      </div>
    </section>
  );
};

export default Roadmap;
